<template>
  <div class="banner">
    <div>我的折扣券</div>
    <div>
      <el-button type="default" @click="back">返回购券</el-button>
    </div>
  </div>
  <div class="container">
    <div v-for="d in list" :key="d.code" class="item">
      <div class="info">
        <div>券码: {{ d.code }}</div>
        <div>有效期: {{ d.startTime }} 至 {{ d.startTime }}</div>
      </div>
      <div class="status">
        {{ status(d.status) }}
      </div>
    </div>
  </div>
</template>

<script>
  import {
    getMyVipCodeList
  } from "@/api/sunrise"

  export default {
    data() {
      return {
        membercode: '',
        list: []
      }
    },
    mounted() {
      this.membercode = sessionStorage.getItem(process.env.VUE_APP_STORE_MEMBERCODE_KEY)
      if (!this.membercode) {
        this.$router.push('/sunrise');
      }
      this.initList()
    },
    methods: {
      initList() {
        getMyVipCodeList('web_' + this.membercode)
          .then(res => {
            if (res.success) {
              this.list = res.list
            }
          })
      },
      status(id) {
        if (id === 0)
          return '未使用'
        else if (id === 1)
          return '已使用'
        else if (id === 2)
          return '已过期'
        else
          return id
      },
      back() {
        this.$router.push('/sunrise/detail');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .banner {
    padding: 0 20px;
    background-color: orange;
    height: 44px;
    line-height: 44px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .container {
    width: 666px;
    max-width: calc(100vw - 20px);
    margin: 0 auto;
    color: #777;

    .item {
      padding: 20px 0;
      border-bottom: solid 1px #ccc;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>
